import React, { useState, useEffect } from 'react';
import Slider from "react-slick"; 
import newsData from "../Data/news.js"; 
import defaultImage from '../../assets/Latest.jpg'

export default function LatestNews() {
    const [news, setNews] = useState([]);

    useEffect(() => {
        setNews(newsData);
    }, []);

    
    const settings = {
        infinite: true, 
        speed: 2000, 
        slidesToShow: 4, 
        slidesToScroll: 1, 
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="latest-news-carousel" style={{ backgroundColor: '#f4f3f8', padding: '30px' }}>
            <h2 className="text-center mb-5" style={{ color: "#f9313b" ,fontWeight:600 }}>Latest News</h2>
            <Slider {...settings}>
                {news.map((item, index) => (
                    <div key={index} className="card mx-2" style={{ width: '90%', height: '400px', borderRadius: '10px' }}>
                        {item.video ? (
                            <iframe
                                width="100%"
                                height="100%"
                                src={item.video}
                                title={item.title || 'News Video'}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ height: '195px', objectFit: 'cover', borderRadius: '3px 3px 10px 10px' }}
                            ></iframe>
                        ) : (
                            <img
                                src={item.image || defaultImage}
                                className="card-img-top"
                                alt={item.title || 'News Image'}
                                style={{ height: '200px', objectFit: 'cover', borderRadius: '3px 3px 10px 10px' }}
                            />
                        )}
                        <div className="card-body text-center" style={{ overflow: 'hidden', height: 'calc(100% - 200px)' }}>
                            <h5 className="card-title text-primary" style={{ fontSize: '1rem' }}>{item.title || 'Untitled News'}</h5>
                            <p className="card-text text-secondary" style={{ fontSize: '0.9rem', height: '60px', overflow: 'hidden' }}>
                                {item.description || 'No description available.'}
                            </p>
                            {item.link && (
                                <a href={item.link} className="btn btn-danger" rel="noopener noreferrer">
                                    Know More
                                </a>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
